<ng-container *ngIf="usuario">
    <app-layout
        appName="SGM"
        appDescription="Sistema de Gestão de Manutenções"
        [menuItens]="menuItens"
        darkThemeClass="dark-theme"
        [usuario]="usuario.name"
        (logout)="handleLogout()"
        [contatoInfo]="contatoInfo"
        [sobreInfo]="sobreInfo"
    >
        <router-outlet></router-outlet>
    </app-layout>
</ng-container>
