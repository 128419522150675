import { Component, OnInit } from '@angular/core';
import { MenuDefinition } from './shared/components/menu/menu-definition';
import { AuthService } from './core/service/auth.service';
import { Observable } from 'rxjs';
import { Usuario } from './core/models/usuario.model';
import { ApiService } from './core/service/api.service';
import { Api } from './core/service/models/api.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'SGM';
    user$: Observable<Usuario>;
    api: Api;
    //@TODO Remover esse usuário
    usuario: Usuario;

    menuItens: MenuDefinition[] = [
        {
            label: 'Itens de Inspeção',
            icon: 'list_alt',
            subitens: [
                {
                    label: 'Grupo',
                    icon: 'assignment',
                    route: '',
                    subitens: [
                        {
                            label: 'Pesquisar Grupo',
                            icon: 'search',
                            route: '/grupo-itens-inspecao/pesquisar',
                        },
                        {
                            label: 'Cadastrar Grupo',
                            icon: 'note_add',
                            route: '/grupo-itens-inspecao/cadastrar',
                        }
                    ],
                },
                {
                    label: 'Itens',
                    icon: 'add_circle',
                    route: 'list_alt',
                    subitens: [
                        {
                            label: 'Pesquisar Itens',
                            icon: 'search',
                            route: '/itens-grupos/pesquisar',
                        },
                        {
                            label: 'Cadastrar Itens',
                            icon: 'note_add',
                            route: '/itens-grupos/cadastrar',
                        }
                    ],
                },
                {
                    label: 'Opções',
                    icon: 'add_circle',
                    route: 'toc',
                    subitens: [
                        {
                            label: 'Pesquisar Opções',
                            icon: 'search',
                            route: '/opcao-itens/pesquisar',
                        },
                        {
                            label: 'Cadastrar Opções',
                            icon: 'note_add',
                            route: '/opcao-itens/cadastrar',
                        }
                    ],
                },
            ],
        },
        {
            label: 'Itens de Serviço',
            icon: 'list',
            subitens: [
                {
                    label: 'Pesquisar Item de Serviço',
                    icon: 'search',
                    route: '/itens-servico/pesquisar'
                },
                {
                    label: 'Cadastrar Item de Serviço',
                    icon: 'add_circle',
                    route: '/itens-servico/cadastrar'
                },
            ],
        },
        {
            label: 'Ordem de Serviço',
            icon: 'settings',
            subitens: [
                {
                    label: 'Pesquisar Ordem de Serviço',
                    icon: 'search',
                    route: '/ordem-servico/pesquisar'
                },
                {
                    label: 'Cadastrar Ordem de Serviço',
                    icon: 'add_circle',
                    route: '/ordem-servico/cadastrar',
                    
                },
            ],
        },
        {
            label: 'Empresa',
            icon: 'toc',
            subitens: [
                {
                    label: 'Pesquisar Empresas',
                    icon: 'search',
                    route: '/empresa/pesquisar'
                },
                {
                    label: 'Cadastrar Empresas',
                    icon: 'add_circle',
                    route: '/empresa/cadastrar'
                },
            ],
        },
    ];

    constructor(
        private authService: AuthService,
        private apiService: ApiService
    ) {
        this.authService.login();
        this.authService.getUser().subscribe((usuario) => {
            this.usuario = usuario;
        });
    }

    handleLogout() {
        this.authService.logout();
    }


    get sobreInfo(): String {
        return `<p>SGM - Sistema de Gestão de Manutenções</p>
        <p>Versão API: ${this.api?.buildVersion}</p>`;
    }

    get contatoInfo(): String {
        return `Qualquer dúvida, entrar em contato com:<br /><br />
            <b>DITEL/SAU:</b> 85800 ou 31905800<br />`;
    }
}
