import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RecursoGuard} from './core/service/recurso-guard.service';

const routes: Routes = [
    {
        path: 'grupo-itens-inspecao',
        canActivate: [],
        loadChildren: () =>
            import('./grupo-itens-inspecao/grupo-itens-inspecao.module').then((m) => m.GrupoModule),
    },
    {
        path: 'itens-grupos',
        canActivate: [],
        loadChildren: () =>
            import('./itens-grupos/itens-grupos.module').then((m) => m.ItensGruposModule),
    },
    {
        path: 'opcao-itens',
        canActivate: [],
        loadChildren: () =>
        import('./opcao-itens/opcao-itens.module').then((m) => m.OpcaoItensModule),
    },
    {
        path: 'itens-servico',
        canActivate: [],
        loadChildren: () =>
        import('./itens-servico/itens-servico.module').then((m) => m.ItemServicoModule),
    },
    {
        path: 'empresa',
        canActivate: [],
        loadChildren: () =>
        import('./empresa/empresa.module').then((m) => m.EmpresaModule),
    },
    {
        path: 'ordem-servico',
        canActivate: [],
        loadChildren: () =>
        import('./ordem-servico/ordem-servico.module').then((m) => m.OrdemServicoModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [RecursoGuard],
})
export class AppRoutingModule {
}
